<template>
  <div class="container-fluid content">
    <div class="row">
      <div class="col-12">
        <h2 class="title-primary">{{$t('register')}}</h2>
        <b-form class="row mt-5" @submit.prevent="send">
          <div class="form-group col-12 col-sm-4">
            <label for="username">{{$t('username')}}</label>
            <b-form-input name="username" id="username" autofocus v-validate="'required|max:30'" v-model="user.username" :state="validateState('username')" />
            <span class="error-inputs">{{ errors.first('username') }}</span>
          </div>
          <div class="form-group col-12 col-sm-4">
            <label for="password">{{$t('password')}}</label>
            <b-form-input name="password" id="password" type="password" v-validate="'required|min:6|max:30'" v-model="user.password" ref="password" :state="validateState('password')" />
            <span class="error-inputs">{{ errors.first('password') }}</span>
          </div>
          <div class="form-group col-12 col-sm-4">
            <label for="repeatPassword">{{$t('repeatPassword')}}</label>
            <b-form-input name="repeatPassword" id="repeatPassword" type="password" v-validate="'required|min:6|max:30|confirmed:password'" v-model="user.repeatPassword" :state="validateState('repeatPassword')" />
            <span class="error-inputs">{{ errors.first('repeatPassword') }}</span>
          </div>
          <div class="form-group col-12 col-sm-4">
            <label for="email">{{$t('email')}}</label>
            <b-form-input name="email" id="email" v-validate="'required|email'" v-model="user.email" :state="validateState('email')" />
            <span class="error-inputs">{{ errors.first('email') }}</span>
          </div>
          <div class="form-group col-12 col-sm-4">
            <label for="country">{{$t('country')}}</label>
            <multiselect v-validate="'required'" :state="validateState('country')" name="country" id="country" v-model="user.country" :options="availableCountries" deselectLabel="" track-by="name" label="name" :placeholder="$t('selectOption')" :searchable="true" :allow-empty="false" selectLabel="" selectedLabel="">
              <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
            </multiselect>
            <span class="error-inputs">{{ errors.first('country') }}</span>
          </div>
          <div class="form-group col-12 col-sm-4">
            <label for="position">{{$t('position')}}</label>
            <multiselect v-validate="'required'" :state="validateState('position')" name="position" id="position" v-model="user.position" :options="availablePositions" deselectLabel="" track-by="value" label="text" :placeholder="$t('selectOption')" :searchable="true" :allow-empty="false" selectLabel="" selectedLabel="">
              <template slot="singleLabel" slot-scope="{ option }">{{ option.text }}</template>
            </multiselect>
            <span class="error-inputs">{{ errors.first('position') }}</span>
          </div>
          <div class="form-group col-12 col-sm-4">
            <label for="motto">{{$t('motto')}}</label>
            <b-form-input name="motto" id="motto" v-validate="'required|max:50'" v-model="user.motto" :state="validateState('motto')" />
            <span class="error-inputs">{{ errors.first('motto') }}</span>
          </div>
          <div class="form-group col-12 col-sm-4">
            <label for="console">{{$t('console')}}</label>
            <multiselect v-validate="'required'" :state="validateState('console')" name="console" id="console" v-model="user.console" :options="consoles" deselectLabel="" :placeholder="$t('selectOption')" :searchable="false" selectLabel="" selectedLabel="" @select="setNick">
            </multiselect>
            <span class="error-inputs">{{ errors.first('console') }}</span>
          </div>
          <div class="form-group col-12 col-sm-4">
            <label for="psnId">{{nick}}</label>
            <b-form-input name="psnId" id="psnId" v-validate="'required|max:30'" v-model="user.psnId" :state="validateState('psnId')" data-vv-as="nick" />
            <span class="error-inputs">{{ errors.first('psnId') }}</span>
          </div>
          <div class="col-12">
            <b-button type="submit" :disabled="loading" variant="primary">{{$t('submit')}}</b-button>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      user: {},
      availableCountries: [],
      availablePositions: [],
      consoles: [],
      nick: ''
    }
  },
  computed: {
    ...mapGetters([
      'loading',
      'lang',
      'countries'
    ])
  },
  mounted () {
    document.title = `${this.$t('registerTitle')} - ITFA`
    this.availablePositions = window.positions
    this.consoles = window.consoles
    this.user.console = 'PSN'
    this.setNick(this.user.console)
    if (this.countries.length < 1) {
      this.fetchCountries()
    } else {
      this.setCountriesForSelect(this.countries)
    }
  },
  methods: {
    send () {
      this.$validator.validate().then(result => {
        if (result) {
          const payload = {
            username: this.user.username,
            password: this.user.password,
            password_confirmation: this.user.repeatPassword,
            console: this.user.console,
            country_id: this.user.country.id,
            position: this.user.position.value,
            motto: this.user.motto,
            nick: this.user.psnId,
            email: this.user.email
          }
          const path = `${this.lang}/users/register`
          this.$axios.post(path, payload).then((response) => {
            this.$toastr.success(response.data.message, window.TITLE_SUCCESS)
            this.$router.push({ name: 'Login', params: { lang: this.lang } })
          })
        } else {
          this.showValidationsErrors(this.errors.items);
        }
      })
    },
    fetchCountries () {
      this.$axios.get('countries').then((response) => {
        const data = response.data.data
        this.$store.dispatch('SET_COUNTRIES', data)
        this.setCountriesForSelect(data)
      })
    },
    setCountriesForSelect (countries) {
      this.availableCountries = countries.map((country) => {
        return {
          id: country.id,
          name: country.name
        }
      })
    },
    setNick (console) {
      if (console === 'PSN') {
        this.nick = 'Psn id'
      }
      if (console === 'XBOX') {
        this.nick = 'Gamer tag'
      }
      if (console === 'PC') {
        this.nick = 'Origin'
      }
    },
  }
}
</script>
